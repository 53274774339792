<template>
    <div class="choose-view">
        <div class="row">
            <div class="col-12 m-col-6">
                <h1>Maak van je oude verf, nieuwe&nbsp;verf</h1>
                <h2>Meter voor meter beter</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 m-col-6 l-col-4">
                <div class="block">
                    <h3>Ik ben een consument</h3>
                    <p>Je bent een doe-het-zelf schilder die in en om het huis verfklussen uitvoert.</p>
                    <router-link to="/consument" class="button">Consument</router-link>
                </div>
            </div>
            <div class="col-12 m-col-6 l-col-4">
                <div class="block">
                    <h3>Ik ben een vakschilder</h3>
                    <p>Je bent een vakschilder die bij bedrijven en particulieren schildert.</p>
                    <router-link to="/professional" class="button">Vakschilder</router-link>
                </div>
            </div>
            <div class="col-12 l-col-4">
                <img src="/static/img/pppw.png" class="pppwlogo" alt="PPPW Logo">
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "base/settings";
    .choose-view{
        background: white url(/static/img/header-choose.jpg) no-repeat 85% 0;
        background-size: cover;
        min-height: 100vh;
        padding: 100px 10px 10px 10px;
        @media (min-width: $medium ) {
            padding: 100px 30px 30px 30px;
        }
        h1 {
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1.15;
        }
        h2 {
            font-family: 'HelveticaNeue';
            margin-bottom: 2em;
        }
        .block {
            background: rgba( 255, 255, 255, 0.40 );
            border-radius: 1rem;
            padding: 1rem;
            margin-bottom: 1rem;
            h3 {
                margin-top: 0;
            }
            p {
                min-height: 4em;
            }
        }

        .pppwlogo {
            display: none;
        }

        @media (min-width: $medium ) {

            .pppwlogo {
                display: block;
                position: absolute;
                bottom: 25px;
                right: 25px;
            }

            .block {
                padding: 2rem;
            }
        }
        @media (min-width: $large ) {
            background: white url(/static/img/header-choose.jpg) no-repeat 25% 0;
            background-size: cover;
        }
    }
</style>

<script>
    import gsap from 'gsap';
    export default {
        name: 'Landing',

        mounted()
        {

        },

        methods: {
            getAnimateIn()
            {
                const tl = gsap.timeline();
                tl.from( 'h1', { opacity: 0, duration: 0.3, ease: 'sine.inOut' }, 0 );
                tl.from( 'h2', { opacity: 0, duration: 0.3, ease: 'sine.inOut' }, 0.3 );
                tl.from( '.block', { opacity: 0, duration: 0.5, x: -20, stagger: 0.2, ease: 'sine.inOut' }, 0.5 );
                return tl;
            }
        }
    };
</script>
